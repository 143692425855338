/**
 * Created by john on 23/9/16.
 */
'use strict';

/* exported
 countriesCtrl
  */

function countriesCtrl($scope, countries, appConfig, $location) {
  $scope.countries = countries;
  $scope.filters = {};

  const search = $location.search();

  // On init if name is filtered
  if (search.name) {
    $scope.filters.name = search.name;
  }

  /**
   * Paging
   */
  // On init if page is above 1
  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.countries.meta.total;
  $scope.pageChanged = function() {
    const limit = 20;
    const offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.countries.getList($scope.filters).then(function(countries) {
      $scope.countries = countries;
    });
  };

  $scope.$watch('currentPage', function(page) {
    const search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });

  /**
   * Filters
   */

  $scope.$on('filters.name', function(event, data) {
    $scope.filters.name = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;
    $scope.filters.withPagination = true;

    $scope.countries.getList($scope.filters).then(function(countries) {

      $scope.currentPage = 1;
      $scope.countries = countries;
      $scope.totalItems = $scope.countries.meta.total;
    });
  });
}

/**
 * Created by john on 2/8/17.
 */
'use strict';

/* exported
  menuCountriesCtrl
 */
function menuCountriesCtrl($scope, $rootScope, $location) {
  $scope.filters = {};

  // On init if country is selected display it
  const search = $location.search();

  if (search.countryName) {
    $scope.filters.name = search.name;
  }

  $scope.$watch('filters.name', function(name) {
    $rootScope.$broadcast('filters.name', name);

    const search = $location.search();
    search.name = name;
    $location.search(search);
  });

  $scope.reset = function() {
    $scope.filters = {};
    $location.search({});
  };
}

/**
 * Created by john on 2/8/17.
 */
'use strict';

/* exported
 countryViewCtrl
 */

function countryViewCtrl($scope, country, appConfig) {
  $scope.country = country;
}

/**
 * Created by john on 3/8/17.
 */
"use strict";

/* exported
 countryEditCtrl
 */

function countryEditCtrl($scope, country, Restangular, $state) {
  $scope.country = country || {};

  if ($scope.country.latlng) {
    $scope.country.latlng = $scope.country.latlng.join(',')
  }

  $scope.save = function () {
    $scope.globalError = null;

    if ($scope.countryForm.$invalid) {
      $scope.countryForm.officialName.$setTouched();
      $scope.countryForm.commonName.$setTouched();
      $scope.countryForm.dialingCode.$setTouched();
      $scope.countryForm.code.$setTouched();
      $scope.countryForm.capital.$setTouched();

      return;
    }

    if ($scope.country.latlng) {
      $scope.country.latlng = $scope.country.latlng.replace(/\s/g, "");
    }

    const promise = $scope.country._id
      ? $scope.country.put()
      : Restangular.all("v2/countries").post($scope.country);

    promise
      .then(function (country) {
        $scope.$emit("notify", {
          type: "success",
          title: "Create country",
          message: "Country created with success",
        });
        $scope.country = country;
        $state.go("root.editCountry", { countryId: country._id });
      })
      .catch(function (err) {
        $scope.$emit("notify", {
          type: "error",
          title: "Create country",
          message: "Failed to create country",
        });

        $scope.globalError = err.data.message;
      });
  };
}
